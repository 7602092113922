<template>
  <div class="home">
    <table-new
      :items="BOOKLIST"
      :title="TITLE"
      :counts="COUNT"
      :headers="BOOKFIELDS"
      :toEditItem="editedItem"
      :toEditHeaders="toEditHeaders"
      :hideBlockTop="true"
      :hideBlockEdit="true"
      :hideBlockViewing="false"
      :hideBlockBtn="false"
      :load="load"
      @update="onUpdate"
      @deleteRecord="_deleteRecord"
      @createRecord="_createBookItem"
      @editRecord="_editBookItem"
      @changeEditItem="changeEditItem"
      @getItemForEdit="getItemForEdit"
      @add="onAdd()"
      @onPagination="
        getFilterData(objectProfile, objectVehicle, sortOptions, ...arguments)
      "
      @sortBy="getFilterData(objectProfile, objectVehicle, ...arguments, page)"
      :page="page"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import tableNew from "@/components/TableNew.vue";

export default {
  name: "transport",
  components: {
    tableNew,
  },
  data: () => {
    return {
      editedItem: null,
      toEditHeaders: [],
      page: 1,
      load: true,
      objectProfile: undefined,
      objectVehicle: undefined,
      sortOptions: undefined,
    };
  },
  computed: {
    ...mapGetters(["BOOKLIST", "TITLE", "COUNT", "BOOKFIELDS", "ADDINGFIELDS"]),
  },
  methods: {
    ...mapActions([
      "getBookList",
      "deleteBookItem",
      "createBookItem",
      "editBookItem",
      "getItemByID",
      "getFields",
    ]),
    getFilterData(
      profile = this.objectProfile,
      vehicle = this.objectVehicle,
      sort = { ordering_field: "id", desc: true },
      page = this.page
    ) {
      this.objectProfile = profile;
      this.objectVehicle = vehicle;
      this.sortOptions = sort;
      this.page = page;

      let desc =
        this.sortOptions.desc.length > 0 && this.sortOptions.desc[0] ? "" : "-";
      let arg = "?";
      this.page ? (arg = arg + `page=${this.page}`) : "";
      this.objectProfile
        ? (arg = arg + `&driver=${this.objectProfile.id}`)
        : "";
      this.objectVehicle
        ? (arg = arg + `&vehicle=${this.objectVehicle.id}`)
        : "";
      this.sortOptions.ordering_field != ""
        ? (arg =
            arg +
            `${
              this.objectProfile || this.objectVehicle || this.page ? "&" : ""
            }ordering=${desc}${this.sortOptions.ordering_field}`)
        : "";
      this.getBookList({ book: "vehicle", args: arg });
    },
    getList(page) {
      this.page = page;
      this.getBookList({ book: "vehicle", args: `?page=${page}` });
    },
    onAdd() {
      this.getFields({ book: "Vehicle" }).finally(() => {
        this.toEditHeaders = this.ADDINGFIELDS;
      });
    },
    onUpdate() {
      this.load = true;
      setTimeout(() => {
        this.page = 1;
        this.getFilterData();
        this.load = false;
      }, 1000);
    },
    getItemForEdit(id) {
      this.getItemByID({ book: "vehicle", id: id }).then((item) => {
        this.editedItem = item.item;
        this.toEditHeaders = item.headers;
      });
    },
    changeEditItem(newVal) {
      this.editedItem = newVal;
    },
    _deleteRecord(id) {
      this.deleteBookItem({ book: "vehicleDelete", id: id.id }).finally(() => {
        this.getFilterData();
      });
    },
    _createBookItem(data) {
      this.load = true;
      this.createBookItem({ book: "vehicleCreate", data: data }).finally(() => {
        this.getFilterData();
        this.load = false;
      });
    },
    _editBookItem(data) {
      this.editBookItem({
        book: "vehicleEdit",
        id: `${data.id}/`,
        data: data,
      }).finally(() => {
        this.getFilterData();
      });
    },
  },
  mounted() {
    this.load = false;
  },
};
</script>
